"use client";

import { Suspense } from "react";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";

import PostHogPageView from "./posthog";

if (typeof window !== "undefined") {
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY as string, {
    api_host: "/ingest",
    capture_pageview: false,
  });
}

function ClientProviders(props: { children: React.ReactNode }) {
  return (
    <PostHogProvider client={posthog}>
      {props.children}

      <Suspense>
        <PostHogPageView />
      </Suspense>
    </PostHogProvider>
  );
}

export default ClientProviders;
