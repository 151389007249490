"use client";

import { useEffect, useState } from "react";
import Image from "next/image";
import { Button } from "@/components/ui/button";
import { usePostHog } from "posthog-js/react";

import CookieIcon from "../../public/logo_icon.svg";

export default function CookieBanner() {
  const posthog = usePostHog();
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    if (
      !(posthog.has_opted_in_capturing() || posthog.has_opted_out_capturing())
    ) {
      setShowBanner(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!showBanner) {
    return null;
  }

  const acceptCookies = () => {
    posthog.opt_in_capturing(); // new
    setShowBanner(false);
  };

  const declineCookies = () => {
    posthog.opt_out_capturing(); // new
    posthog.stopSessionRecording();
    setShowBanner(false);
  };

  return (
    <div className="border-content-tertiary bg-background fixed bottom-4 right-4 z-50 mx-4 flex flex-col items-center justify-center gap-4 rounded-xl border p-4 sm:mx-auto sm:w-[600px] sm:flex-row">
      <Image
        src={CookieIcon}
        width={32}
        height={32}
        alt="cookie_icon"
        className="m-4"
      />
      <p className="text-center text-sm font-light sm:text-left">
        {
          "On utilise des cookies pour comprendre comment vous utilisez Timepilot et nous aider à l'améliorer."
        }
      </p>
      <div className="flex flex-row items-center gap-4">
        <Button variant="outline" type="button" onClick={declineCookies}>
          Refuser
        </Button>
        <Button variant="outline" type="button" onClick={acceptCookies}>
          Accepter
        </Button>
      </div>
    </div>
  );
}
