"use client";

import type { FC } from "react";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { cn } from "@/utils/cn";

import { Button } from "../ui/button";

export const HeaderNavigation: FC = () => {
  const pathname = usePathname();

  return (
    <nav className="hidden grow justify-end px-2 md:flex">
      <Button
        variant="ghostLink"
        className="text-base font-light underline-offset-8"
        asChild
      >
        <Link
          href="/discover/paris"
          className={cn(pathname.includes("/discover") && "underline")}
        >
          Trouve tes séances
        </Link>
      </Button>

      <Button
        variant="ghostLink"
        className="text-base font-light underline-offset-8"
        asChild
      >
        <Link
          href="/cinemas/paris"
          className={cn(pathname.includes("/cinemas") && "underline")}
        >
          Les cinémas
        </Link>
      </Button>

      {/* <Button
        variant="ghostLink"
        className="text-base font-light underline-offset-8"
        asChild
      >
        <Link
          href="/about"
          className={cn(pathname.includes("/about") && "underline")}
        >
          A propos
        </Link>
      </Button> */}

      {/* <Button
        variant="ghostLink"
        className="text-base font-light underline-offset-8"
        asChild
      >
        <Link
          href="/blog"
          className={cn(pathname.includes("/blog") && "underline")}
        >
          Blog
        </Link>
      </Button> */}
    </nav>
  );
};
