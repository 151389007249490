import type { FC } from "react";
import Image from "next/image";
import Link from "next/link";

import LogoSVG from "../../../public/logo.svg";

export const HeaderLogo: FC = () => {
  return (
    <Link href="/" rel="canonical">
      <Image
        src={LogoSVG}
        alt="timepilot_logo"
        className="h-16 w-24 transition-opacity hover:opacity-80 sm:h-20 sm:w-32"
        color="#FFFFFF"
      />
    </Link>
  );
};
