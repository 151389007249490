import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/website-v2/src/app/client-providers.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/website-v2/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/website-v2/src/components/cookie-banner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/website-v2/src/components/footer/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/website-v2/src/components/header/Header.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"./satoshi.ttf\",\"variable\":\"--font-satoshi\"}],\"variableName\":\"satoshi\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"../../node_modules/geist/dist/sans.js\",\"import\":\"\",\"arguments\":[{\"src\":\"./fonts/geist-sans/Geist-Variable.woff2\",\"variable\":\"--font-geist-sans\",\"weight\":\"100 900\"}],\"variableName\":\"GeistSans\"}");
