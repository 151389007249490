"use client";

import { useCallback, useEffect, useState } from "react";
import type { FC } from "react";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { cn } from "@/utils/cn";

import { Button } from "../ui/button";
import { HeaderLogo } from "./HeaderLogo";
import { HeaderNavigation } from "./HeaderNavigation";

// import { HeaderSearch } from "./HeaderSearch";

const Header: FC = () => {
  const [visible, setVisible] = useState(true);
  const [currentScrollY, setCurrentScrollY] = useState(0);
  const [lastScrollY, setLastScrollY] = useState(0);
  const pathname = usePathname();

  const controlHeaderVisibility = useCallback(() => {
    // Check the current scroll position
    const currentScrollY = window.scrollY;

    // Hide header when scrolling down, and show when scrolling up
    if (currentScrollY > lastScrollY && currentScrollY > 48) {
      setVisible(false);
    } else {
      setVisible(true);
    }

    // Set the current scroll position as the last one
    setCurrentScrollY(currentScrollY);

    // Update the last scroll position
    setLastScrollY(currentScrollY);
  }, [lastScrollY]);

  useEffect(() => {
    window.addEventListener("scroll", controlHeaderVisibility);

    return () => {
      window.removeEventListener("scroll", controlHeaderVisibility);
    };
  }, [lastScrollY, controlHeaderVisibility]);

  const percent = currentScrollY / 100;

  if (pathname.includes("/invite") || pathname.includes("/watchlist")) {
    return null;
  }

  return (
    <header
      className={cn(
        "md:header-slide sticky top-0 z-50 w-full py-0.5",
        !visible && "md:header-hidden",
        percent > 1 && "backdrop-blur",
      )}
      style={{
        background: `rgba(6,6,6, ${percent > 1 ? 0.8 : percent})`,
      }}
    >
      <div className="container flex w-full items-center justify-between gap-4 md:justify-start">
        <HeaderLogo />

        <HeaderNavigation />

        {/* <HeaderSearch /> */}

        <Button
          size="sm"
          className="rounded-full px-4 text-xs font-bold uppercase tracking-wide transition-opacity hover:opacity-50"
          asChild
        >
          <Link href={"/download"}>{"Télécharge l'appli"}</Link>
        </Button>

        {/* <HeaderMobileNavigation /> */}
      </div>
    </header>
  );
};

export default Header;
