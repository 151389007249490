"use client";

import type { FC } from "react";
import Image from "next/image";
import Link from "next/link";
import { usePathname } from "next/navigation";

import InstagramSvg from "../../../public/assets/instagram.svg";
import LinkedinSvg from "../../../public/assets/linkedin.svg";
import TikTokSvg from "../../../public/assets/tiktok.svg";
import Tmdb from "../../../public/assets/tmdb.svg";
import TwitterSvg from "../../../public/assets/twitter.svg";
import GooglePlayBadgeSVG from "../../../public/google_play_badge.svg";
import iOSBadgeSVG from "../../../public/ios_badge.svg";
import LogoIcon from "../../../public/logo_icon.svg";

const Footer: FC = () => {
  const pathname = usePathname();
  if (pathname.includes("/invite") || pathname.includes("/watchlist")) {
    return null;
  }
  return (
    <footer className="container mx-auto mt-4 grid w-full grid-cols-1 gap-4 px-4 py-8 lg:grid-cols-3">
      <div className="flex flex-col items-center gap-4 lg:items-start">
        <Link
          href="/"
          className="flex items-center text-sm text-gray-400 transition duration-300 hover:opacity-75"
        >
          <Image src={LogoIcon} width={22} height={22} alt="logo" />
          <span className="ml-2.5 text-sm font-medium">
            Timepilot: horaires & séances de cinéma
          </span>
        </Link>

        <div className="flex justify-center lg:justify-start">
          <a href="https://apps.apple.com/fr/app/timepilot-s%C3%A9ances-de-film/id6457365664?itsct=apps_box_link&itscg=30200">
            <Image
              src={iOSBadgeSVG}
              width={128}
              height={32}
              alt="Disponible sur l'App Store"
              className="-ml-1 h-12 p-1 transition duration-300 hover:opacity-75"
              priority
            />
          </a>

          <a href="https://play.google.com/store/apps/details?id=app.timepilot&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
            <Image
              src={GooglePlayBadgeSVG}
              width={128}
              height={32}
              alt="Disponible sur Google Play"
              className="h-12 transition duration-300 hover:opacity-75"
              priority
            />
          </a>
        </div>
      </div>

      <div className="flex flex-col items-center justify-center gap-4">
        <ul className="mt-3 flex flex-wrap text-sm font-medium text-gray-400 sm:mt-0">
          <li>
            <Link href="/policy" className="mr-4 hover:underline md:mr-6">
              {"Politique de confidentialité"}
            </Link>
          </li>
          <li>
            <Link href="/contact" className="hover:underline">
              Contact
            </Link>
          </li>
        </ul>

        <span className="text-sm text-gray-400">
          © 2024{" "}
          <Link href="/" className="hover:underline">
            Timepilot
          </Link>
          . Données fournies par{" "}
          <a
            href="https://themoviedb.org"
            className="transition-all hover:underline hover:opacity-50"
            target="_blank"
          >
            <Image
              src={Tmdb}
              width={40}
              height={20}
              alt="tmdb"
              className="ml-1 mr-2 inline-block"
            />
          </a>
          .
        </span>
      </div>

      <div className="hidden flex-col items-end justify-center gap-2 lg:flex">
        <span className="text-sm text-gray-400">
          Suivez nous sur les réseaux
        </span>
        <div
          id="social_medias"
          className="flex justify-center gap-4 sm:justify-end"
        >
          <a
            href="https://www.linkedin.com/company/100459890"
            target="_blank"
            className="flex items-center justify-center"
          >
            <Image
              src={LinkedinSvg}
              width={20}
              height={20}
              className="opacity-75 transition-all duration-300 hover:opacity-100"
              alt="linkedin"
            />
          </a>
          <a
            href="https://www.tiktok.com/@timepilot_app"
            target="_blank"
            className="flex items-center justify-center"
          >
            <Image
              src={TikTokSvg}
              width={20}
              height={20}
              className="rounded-lg fill-gray-400 p-0.5 opacity-75 transition-all duration-300 hover:opacity-100"
              alt="tiktok"
            />
          </a>
          <a
            href="https://twitter.com/timepilot_app"
            target="_blank"
            className="flex items-center justify-center"
          >
            <Image
              src={TwitterSvg}
              width={20}
              height={20}
              className="opacity-75 transition-all duration-300 hover:opacity-100"
              alt="twitter"
            />
          </a>
          <a
            href="https://www.instagram.com/timepilot_app/"
            target="_blank"
            className="flex items-center justify-center"
          >
            <Image
              src={InstagramSvg}
              width={20}
              height={20}
              className="opacity-75 transition-all duration-300 hover:opacity-100"
              alt="instagram"
            />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
