"use client";

import { useEffect } from "react";
import { usePathname } from "next/navigation";
import posthog from "posthog-js";

const PostHogPageView = () => {
  const pathname = usePathname();
  // const searchParams = useSearchParams();

  useEffect(() => {
    posthog.startSessionRecording({});
    posthog._start_queue_if_opted_in();
  }, []);

  // Track pageviews
  useEffect(() => {
    if (pathname) {
      const url = window.origin + pathname;
      // if (searchParams.toString()) {
      //   url = url + `?${searchParams.toString()}`;
      // }
      posthog.capture("$pageview", {
        $current_url: url,
      });
    }
  }, [pathname]);

  return null;
};

export default PostHogPageView;
